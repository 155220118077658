import React, { ReactElement } from "react";

export default function SecureIcon(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="32"
            viewBox="0 0 26 32"
        >
            <path
                fill="#3EDC84"
                fillRule="nonzero"
                d="M24.907 5.862a27.823 27.823 0 01-10.026-4.8L13.867.291a1.437 1.437 0 00-1.734 0l-1.01.766a27.827 27.827 0 01-10.027 4.8A1.454 1.454 0 000 7.273v6.72a17.226 17.226 0 006.796 13.684l5.337 4.032c.514.388 1.22.388 1.734 0l5.344-4.032A17.227 17.227 0 0026 13.993v-6.72c0-.667-.45-1.25-1.093-1.411zm-6.33 8.685l-6.018 6.013a1.505 1.505 0 01-2.127 0l-3.01-3.006a1.502 1.502 0 01.019-2.107 1.506 1.506 0 012.109-.019l1.945 1.944 4.955-4.95a1.506 1.506 0 012.11.018c.58.58.588 1.518.018 2.107z"
            ></path>
        </svg>
    );
}
