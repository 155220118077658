import SecureIcon from "@components/svg/secureIcon";
import React, { ReactElement } from "react";
import styles from "./footer.module.scss";

const footerMenu = [
    { label: "Home", href: "/" },
    { label: "Contact Us", href: "/contact-us" },
    {
        label: "Privacy Policy",
        href: "/privacy-policy",
    },
];

export default function Footer(): ReactElement {
    return (
        <footer className="py-5 pb-10 lg:pb-14 text-center px-6 max-w-7xl mx-auto">
            <div className="inner lg:flex lg:justify-between">
                <div className="lg:w-5/12">
                    <h2 className="text-xl font-bold lg:hidden">
                        Best Antiviruses in {new Date().getFullYear()}
                    </h2>
                    <p className="text-sm font-bold mt-5 lg:text-left">
                        Granted Security with SSL Certificate.
                    </p>
                    <div className="flex flex-row justify-center items-center mt-2 lg:justify-start font-medium">
                        <SecureIcon />
                        <span className="ml-3 text-sm font-light">
                            <strong className="font-bold">SECURE</strong> SSL
                            ENCRYPTION
                        </span>
                    </div>
                </div>
                <div className="mt-5 lg:mb-0 lg:flex lg:flex-col lg:justify-between lg:items-end">
                    <nav>
                        <ul className="flex justify-center">
                            {footerMenu.map((menuItem, index) => (
                                <li key={index} className="pl-6 font-light">
                                    <a
                                        className={`${styles["menu-item"]}`}
                                        href={menuItem.href}
                                    >
                                        {menuItem.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="copyright text-xs font-light mt-3 lg:mt-0">
                        © {new Date().getFullYear()} AntivirusChoice. All Rights
                        Reserved
                    </div>
                </div>
            </div>
        </footer>
    );
}
